// @ts-ignore
import { DateTime } from "luxon";
// @ts-ignore
import { type ReadonlyUint8Array } from "@utils/arrayUtils";
export type ActionableBulkLoadForm = {
  actionableIds: bigint[];
  corporateId: number | null;
  includeInactive: boolean;
};

export type ActionableListPortletView = {
  items: ActionableListView[];
  metadata: PortletMetadata;
  page: number;
  pageSize: number;
  totalCount: bigint;
  viewAllUri: string | null;
};

export type ActionableListView = {
  actionableId: bigint;
  activatedAt: DateTime<true> | null;
  active: boolean;
  coverUri: string;
  description: string;
  language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
  modifiedAt: DateTime<true>;
  name: string;
  title: string;
};

export type ActionableMiniView = {
  actionableId: bigint;
  coverUri: string;
  name: string;
  title: string;
};

export type ActionablePageProps = {
  actionableId: bigint;
  freeActionableActive: boolean;
  isMapp: boolean;
};

export type AdminSidebarWidgetProps = {
  navigationItems: AdminToolLinkGroup[];
};

export type AdminToolLink = {
  description: string | null;
  hidden: boolean;
  ico: string | null;
  linkText: string | null;
  tabName: string | null;
  tabTitle: string | null;
  targetType: AdminToolLinkTargetType;
  url: string | null;
};

export type AdminToolLinkGroup = {
  ico: string | null;
  links: AdminToolLink[];
  name: string | null;
};

export namespace AdminToolLinkTargetType {
  const values = ["SELF", "BLANK", "ROUTER"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type AdminToolLinkTargetType = AdminToolLinkTargetType.Impl;

export namespace AskGetabstractAnalyticsEventVariant {
  const values = ["default", "qa_triggered_from_search", "qa_demo"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type AskGetabstractAnalyticsEventVariant = AskGetabstractAnalyticsEventVariant.Impl;

export type AskGetabstractAnalyticsFeedbackEventForm = {
  isPositive: boolean;
  questionUuid: string;
};

export type AskGetabstractAnswer = {
  answer: string;
  contextDocs: AskGetabstractAnswerContextDoc[];
  detectedLanguageLabel: string | null;
  previousQuestionUuid: string | null;
  question: string;
  questionUuid: string;
  relatedActionables: ActionableMiniView[];
  relatedQuestions: string[];
  status: AskGetabstractAnswerStatus;
};

export type AskGetabstractAnswerContextDoc = {
  bookmarked: boolean;
  dataId: number;
  rating: number | null;
  referenceNumber: number;
  snippet: string;
  sourceTypeString: string;
  title: string;
  urls: {
    cover: string;
    list: string;
    reference: string;
  };
};

export namespace AskGetabstractAnswerStatus {
  const values = ["PROCESSING", "FINISHED", "NO_DOCS_FROM_VECTOR_DB", "NO_DOCS_AFTER_FILTER", "NO_REFERENCE_IN_ANSWER", "GENERAL_ERROR", "TIMEOUT"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type AskGetabstractAnswerStatus = AskGetabstractAnswerStatus.Impl;

export type AskGetabstractProperties = {
  isTrendingQuestion: boolean;
  language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
  question: string | null;
  questionUuid: string | null;
  showAiDataProtectionDisclaimer: boolean;
};

export type AskGetabstractQuestion = {
  analyticsEventVariant: AskGetabstractAnalyticsEventVariant;
  blacklistQuestions: string[];
  previousQuestionUuid: string | null;
  question: string;
  questionUuid: string;
  relatedQuestionStep: number;
  trendingQuestion: boolean;
};

export type AskGetabstractQuestionAnswers = {
  current: AskGetabstractAnswer;
  history: AskGetabstractAnswer[];
};

export type AuthorAdminListFilters = {
  authorId: string | null;
  channels: string | null;
  firstName: string | null;
  gender: string | null;
  lastName: string | null;
  middleName: string | null;
};

export type AuthorAdminListView = {
  authorId: bigint;
  channels: string[];
  firstName: string;
  gender: Gender;
  lastName: string | null;
  middleName: string | null;
};

export type AuthorEditView = {
  authorId: bigint | null;
  firstName: string;
  gender: Gender;
  ignoringWarning: boolean;
  lastName: string | null;
  middleName: string | null;
};

export type B2BStatsReportPageProps = {
  corporateId: number;
};

export type CareerLocalizationForm = {
  benefits: string;
  content: string;
  location: string;
  title: string;
};

export type ChannelAdminSearchForm = {
  corporateId: number | null;
  includeInactive: boolean | null;
  includeTemporary: boolean | null;
  query: string;
};

export type ChannelAdminTagBasedProps = {
  channelId: bigint;
  managerSelection: ChannelManager[];
};

export type ChannelCoverEditForm = { kind: "default" } | { kind: "update"; base64Image: string } | { kind: "existing"; url: string };

export type ChannelEditForm = {
  active: boolean;
  channelId: bigint;
  channelLocalization: { additionalProperties: Record<string, ChannelLocalizationEditForm> };
  cover: ChannelCoverEditForm;
  definition: string | null;
  name: string;
  notes: string | null;
};

export type ChannelEditView = {
  active: boolean;
  channelId: bigint;
  channelLocalization: { additionalProperties: Record<string, ChannelLocalizationView> };
  cover: string | null;
  definition: string | null;
  name: string;
  notes: string | null;
};

export type ChannelLocalizationEditForm = {
  active: boolean;
  description: string | null;
  manager: number | null;
  synonym: string | null;
  title: string | null;
};

export type ChannelLocalizationView = {
  active: boolean;
  description: string | null;
  manager: number | null;
  synonym: string | null;
  title: string | null;
};

export type ChannelManager = {
  id: number;
  name: string;
};

export type ChannelMetadata = {
  activationTime: DateTime<true> | null;
  active: boolean;
  channelId: bigint;
  coverUri: string | null;
  definition: string | null;
  descriptionHtml: string | null;
  modificationTime: DateTime<true> | null;
  name: string;
  synonyms: string | null;
  title: string | null;
};

export type ConnectorCompletionExecutionLogListView = {
  completedAt: DateTime<true> | null;
  connectorExecutionLogId: bigint;
  contentId: string;
  method: string;
  request: string;
  response: string;
  statusCode: number;
  syncedAt: DateTime<true>;
  userId: string;
};

export type ConnectorCompletionFilters = {
  timeFrame: "LAST_24_HOURS" | "LAST_WEEK" | "LAST_2_WEEKS" | "LAST_MONTH" | "LAST_3_MONTHS";
  userId: string | null;
};

export type ConnectorContentExecutionListView = {
  connectorExecutionId: bigint;
  connectorName: string;
  description: string;
  detail: ConnectorContentExecutionLogDetailView;
  provider: ConnectorProviderMetadata;
  syncedAt: DateTime<true> | null;
  type: "WEB" | "BATCH";
};

export type ConnectorContentExecutionLogDetailView = {
  failedExportedItems: number;
  successfullyExportedItems: number;
};

export type ConnectorContentExecutionLogListView = {
  connectorExecutionLogId: bigint;
  contentId: string;
  method: string;
  request: string;
  response: string;
  statusCode: number;
  syncedAt: DateTime<true>;
};

export type ConnectorExecutionLogMetadata = {
  connectorExecutionLogId: bigint;
  contentId: string;
  method: string;
  request: string;
  response: string;
  statusCode: number;
  syncedAt: DateTime<true>;
};

export type ConnectorForm = {
  active: boolean;
  languages: ("en" | "de" | "es" | "ru" | "zh" | "pt" | "fr")[];
  name: string;
  products: ConnectorProduct[];
  settingsForm: ConnectorSettingsForm;
  syncCompletions: boolean;
  type: ConnectorType;
};

export namespace ConnectorGrantType {
  const values = ["CLIENT_CREDENTIALS", "PASSWORD"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ConnectorGrantType = ConnectorGrantType.Impl;

export type ConnectorListView = {
  active: boolean;
  connectorId: bigint;
  name: string;
  provider: ConnectorProviderMetadata;
  syncedAt: DateTime<true> | null;
};

export namespace ConnectorProduct {
  const values = ["BUSINESS_BOOKS", "ARTICLES_AND_REPORTS", "VIDEOS_AND_PODCASTS", "ACTIONABLES"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ConnectorProduct = ConnectorProduct.Impl;

export type ConnectorProviderMetadata = {
  imageUri: string;
  name: string;
  type: ConnectorType;
};

export type ConnectorSettingsForm = {
  accountId: string | null;
  apiKey: string | null;
  clientId: string;
  clientSecret: string;
  doceboChannelId: bigint | null;
  grantType: ("CLIENT_CREDENTIALS" | "PASSWORD") | null;
  lmsApiUri: string | null;
  lmsBaseUri: string;
  lmsTokenUri: string | null;
  oauth2Flow: Oauth2Flow;
  password: string | null;
  scope: string | null;
  sfCompanyId: string | null;
  userId: string | null;
  userIdentifier: ConnectorUserIdentifier;
  username: string | null;
  workdayCategoryFallback: string | null;
};

export type ConnectorSettingsView = {
  accountId: string | null;
  apiKey: string | null;
  clientId: string;
  clientSecret: string;
  doceboChannelId: bigint | null;
  grantType: ConnectorGrantType;
  lmsApiUri: string | null;
  lmsBaseUri: string;
  lmsTokenUri: string | null;
  oauth2Flow: Oauth2Flow;
  password: string | null;
  scope: string | null;
  sfCompanyId: string | null;
  userId: string | null;
  userIdentifier: ConnectorUserIdentifier;
  username: string | null;
  workdayCategoryFallback: string | null;
};

export type ConnectorSyncEntry = {
  connectorId: bigint;
  corporateId: number;
  type: ConnectorType;
};

export namespace ConnectorType {
  const values = ["SUCCESS_FACTORS", "DEGREED", "DOCEBO", "CSOD", "SUM_TOTAL", "EDCAST", "WORKDAY"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ConnectorType = ConnectorType.Impl;

export namespace ConnectorUserIdentifier {
  const values = ["NAME_ID", "EMAIL", "EXTRA_FIELD_1", "EXTRA_FIELD_2", "EXTRA_FIELD_3"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ConnectorUserIdentifier = ConnectorUserIdentifier.Impl;

export type ConnectorView = {
  connectorId: bigint;
  languages: ("en" | "de" | "es" | "ru" | "zh" | "pt" | "fr")[];
  name: string;
  products: ConnectorProduct[];
  settings: ConnectorSettingsView;
  syncCompletions: boolean;
  type: ConnectorType;
};

export type Country = {
  countryCode: string;
  currency: "CHF" | "CNY" | "USD" | "EUR" | "GBP" | "INR" | "JPY";
  names: {
    de: string;
    en: string;
    es: string;
    fr: string;
    pt: string;
    ru: string;
    zh: string;
  };
  phonePrefix: string;
  region: "SOUTHAMERICA" | "EUROPE" | "OCEANIA" | "NORTHAMERICA" | "AFRICA" | "ANTARCTICA" | "ASIA";
};

export type CustomPageAdminSearchForm = {
  includeInactive: boolean;
  onlyHighlighted: boolean;
  searchTerm: string;
};

export type CustomPageCopyRequest = {
  toCorporateId: number;
};

export type CustomPageEditForm = {
  active: boolean;
  customPageLocalization: { additionalProperties: Record<string, CustomPageLocalizationEditForm> };
  defaultCover: boolean;
  headerLayout: CustomPageHeaderLayout;
  portletIds: number[];
};

export type CustomPageEditListView = {
  active: boolean;
  corporateId: number;
  corporation: string;
  customPageId: bigint;
  highlightPriority: number | null;
  modifiedAt: DateTime<true>;
  titles: { additionalProperties: Record<string, string> };
};

export type CustomPageEditView = {
  active: boolean;
  corporateId: number;
  coverUrl: string;
  customPageId: bigint;
  customPageLocalization: { additionalProperties: Record<string, CustomPageLocalizationEditView> };
  headerLayout: CustomPageHeaderLayout;
  portlets: PortletMetadata[];
};

export type CustomPageFilters = {
  includeInactive: boolean;
  onlyHighlighted: boolean;
};

export namespace CustomPageHeaderLayout {
  const values = ["NONE", "DEFAULT"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type CustomPageHeaderLayout = CustomPageHeaderLayout.Impl;

export type CustomPageHighlightsForm = {
  customPageIds: bigint[];
};

export type CustomPageListView = {
  corporateId: number;
  coverUri: string;
  customPageId: bigint;
  description: string | null;
  title: string;
};

export type CustomPageLocalizationEditForm = {
  description: string | null;
  title: string;
};

export type CustomPageLocalizationEditView = {
  description: string | null;
  title: string;
};

export type CustomPageView = {
  coverUrl: string;
  customPageId: bigint;
  description: string | null;
  headerLayout: CustomPageHeaderLayout;
  portlets: PortletMetadata[];
  title: string;
};

export type CustomerAdminDetailWidgetProps = {
  customerId: number;
};

export type DateRange = {
  from: { year: number; month: number; day: number };
  to: { year: number; month: number; day: number };
};

export namespace DownloadType {
  const values = ["PUSH", "PULL", "BOTH"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type DownloadType = DownloadType.Impl;

export type ErrorResponse = {
  errors: {
    field: string;
    message: string;
  }[];
  type: "validation";
};

export namespace FeatureType {
  const values = ["ACTIONABLE", "ASK_GETABSTRACT"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type FeatureType = FeatureType.Impl;

export type FilteredLearningItemAdminView = {
  items: LearningItemAdminView[];
  totalCount: number;
};

export type FormFilter = {
  activeValues: string[];
  label: string;
  name: string;
  options: FormFilterOption[];
  priority: FormFilterPriority;
  type: FormFilterType;
};

export type FormFilterOption = {
  label: string;
  shortLabel: string | null;
  value: string;
};

export namespace FormFilterPriority {
  const values = ["PRIMARY", "SECONDARY"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type FormFilterPriority = FormFilterPriority.Impl;

export namespace FormFilterType {
  const values = ["MULTICHECKBOX", "CHECKBOX", "MULTISELECT", "RANGE", "RANGE_MIN"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type FormFilterType = FormFilterType.Impl;

export namespace FrontendProject {
  const values = ["VUE", "DESIGN"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type FrontendProject = FrontendProject.Impl;

export type FullSearchProps = {
  initialFormFilters: FormFilter[];
  query: string | null;
};

export type FullSearchResults = {
  actionables: ActionableListView[];
  channels: ChannelMetadata[];
  customPages: CustomPageListView[];
  downloadSourceParams: { additionalProperties: Record<string, string> };
  formFilters: FormFilter[];
  summaries: SummaryMetadata[];
  summariesPaging: {
    page: number;
    totalCount: bigint;
  };
};

export namespace Gender {
  const values = ["FEMALE", "MALE", "UNKNOWN", "CUSTOM"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type Gender = Gender.Impl;

export namespace ItemLayout {
  const values = ["MINI", "REGULAR"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ItemLayout = ItemLayout.Impl;

export namespace KindChannel {
  const values = ["channel"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindChannel = KindChannel.Impl;

export namespace KindClassification {
  const values = ["classification"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindClassification = KindClassification.Impl;

export namespace KindDynamicBoolean {
  const values = ["dynamicBoolean"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindDynamicBoolean = KindDynamicBoolean.Impl;

export namespace KindDynamicNumber {
  const values = ["dynamicNumber"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindDynamicNumber = KindDynamicNumber.Impl;

export namespace KindDynamicString {
  const values = ["dynamicString"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindDynamicString = KindDynamicString.Impl;

export namespace KindDynamicVoid {
  const values = ["dynamicVoid"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindDynamicVoid = KindDynamicVoid.Impl;

export namespace KindKeyQuality {
  const values = ["key-quality"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type KindKeyQuality = KindKeyQuality.Impl;

export namespace Language {
  const values = ["en", "de", "es", "ru", "zh", "pt", "fr"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type Language = Language.Impl;

export type LearningCalendarView = {
  bannerImgUri: string;
  doors: Record<string, never>[];
  endDate: { year: number; month: number; day: number } | null;
  expiresAt: { year: number; month: number; day: number } | null;
  learningCalendarId: bigint;
  name: string;
  startDate: { year: number; month: number; day: number };
};

export type LearningItemAdminActionableView = {
  description: string;
  learningItemType: LearningItemTypeActionable;
  metadata: LearningItemAdminMetadataView;
};

export type LearningItemAdminFilter = {
  activatedAtRange: DateRange | null;
  active: boolean | null;
  audio: boolean | null;
  createdAtRange: DateRange | null;
  documentTypes: SummaryDocumentType[];
  ids: number[];
  itemTypes: LearningItemType[];
  languages: Language[];
  manager: string | null;
  publisher: string | null;
  resultSize: number;
  sourceTypes: SummarySourceType[];
  tagCount: number | null;
  tags: Tag[];
  title: string | null;
};

export type LearningItemAdminMetadataView = {
  activatedAt: { year: number; month: number; day: number } | null;
  active: boolean;
  createdAt: { year: number; month: number; day: number };
  deactivatedAt: { year: number; month: number; day: number } | null;
  id: bigint;
  language: Language;
  manager: string | null;
  tags: Tag[];
  title: string;
};

export type LearningItemAdminProps = {
  filter: LearningItemAdminFilter;
  readonly: boolean;
};

export type LearningItemAdminSummaryView = {
  audioActive: boolean;
  documentType: SummaryDocumentType;
  learningItemType: LearningItemTypeSummary;
  metadata: LearningItemAdminMetadataView;
  publisher: string | null;
  sourceType: SummarySourceType;
};

export type LearningItemAdminView =
  | { learningItemType: "ACTIONABLE"; description: string; metadata: LearningItemAdminMetadataView }
  | { learningItemType: "SUMMARY"; audioActive: boolean; documentType: SummaryDocumentType; metadata: LearningItemAdminMetadataView; publisher: string | null; sourceType: SummarySourceType };

export namespace LearningItemType {
  const values = ["SUMMARY", "ACTIONABLE"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type LearningItemType = LearningItemType.Impl;

export namespace LearningItemTypeActionable {
  const values = ["ACTIONABLE"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type LearningItemTypeActionable = LearningItemTypeActionable.Impl;

export namespace LearningItemTypeSummary {
  const values = ["SUMMARY"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type LearningItemTypeSummary = LearningItemTypeSummary.Impl;

export type MyListPortletView = {
  items: MyListView[];
  metadata: PortletMetadata;
  page: number;
  pageSize: number;
  totalCount: bigint;
  viewAllUri: string | null;
};

export type MyListView = {
  coverUri: string;
  key: string;
  myListId: bigint;
  name: string;
};

export namespace NavigationDirection {
  const values = ["NEXT", "PREVIOUS"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type NavigationDirection = NavigationDirection.Impl;

export namespace Oauth2Flow {
  const values = ["BASIC", "TWO_LEGGED_OAUTH"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type Oauth2Flow = Oauth2Flow.Impl;

export type PageInfo = {
  page: number;
  pageSize: number;
  totalCount: number;
};

export type PagedActionableListView = {
  items: ActionableListView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedAuthorAdminListView = {
  items: AuthorAdminListView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedConnectorCompletionExecutionLogListView = {
  items: ConnectorCompletionExecutionLogListView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedConnectorContentExecutionListView = {
  items: ConnectorContentExecutionListView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type PagedConnectorContentExecutionLogListView = {
  items: ConnectorContentExecutionLogListView[];
  page: number;
  pageSize: number;
  totalCount: bigint;
};

export type Person = {
  firstName: string;
  gender: Gender;
  lastName: string | null;
  middleName: string | null;
};

export namespace PortletLayout {
  const values = ["GRID", "CAROUSEL"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type PortletLayout = PortletLayout.Impl;

export type PortletMetadata = {
  custom: boolean;
  defaultFilterAllLanguages: boolean;
  embedSortingFiltering: boolean;
  hideTitle: boolean;
  itemCount: number;
  itemLayout: ItemLayout;
  name: string;
  portletId: number;
  portletLayout: PortletLayout;
  subType: string | null;
  title: string;
  type: PortletType;
};

export namespace PortletType {
  const values = [
    "IMAGETEXT",
    "SUMMARYLIST",
    "TRENDINGCHANNEL",
    "FEATUREDCHANNEL",
    "FOLLOWINGCHANNEL",
    "MYLIST",
    "PRIMARYTOPICCHANNEL",
    "CHANNELLIST",
    "ANNOTATIONLIST",
    "LGXPLIST",
    "SKETCHNOTESLGXP",
    "ACTIONABLELIST",
    "ACTIONABLELISTCUSTOM",
    "MYACTIONABLES",
    "FREESUMMARY",
    "SUMMARYLISTCUSTOM",
    "CUSTOMPAGELIST",
  ] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type PortletType = PortletType.Impl;

export type ProductAdminView = {
  code: string;
  productId: bigint;
};

export type PromoBoxEditProps = {
  corporateId: number;
  languages: Language[];
  promoBoxId: number;
};

export namespace PushFrequency {
  const values = ["NONE", "DAILY", "ONCE_A_WEEK", "TWICE_A_MONTH", "ONCE_A_MONTH"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type PushFrequency = PushFrequency.Impl;

export type ReferencedItemEdit = {
  refItem: ReferencedLearningItem;
  tags: Tag[];
};

export type ReferencedItemSaveRequest = {
  items: ReferencedItemEdit[];
};

export type ReferencedLearningItem = { learningItemType: "SUMMARY"; id: number } | { learningItemType: "ACTIONABLE"; id: bigint };

export type ReferencedLearningItemList = {
  items: ReferencedLearningItem[];
};

export namespace ReportFrequency {
  const values = ["ONE_TIME", "DAILY", "WEEKLY", "MONTHLY"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ReportFrequency = ReportFrequency.Impl;

export type ReportListView = {
  active: boolean;
  corporateId: number;
  customerFullName: string;
  frequency: ReportFrequency;
  name: string;
  prefixFileName: boolean;
  reportId: number;
  reportType: ReportType;
  targetExcel: boolean;
  timeFrame: string;
  zipFile: boolean;
};

export namespace ReportType {
  const values = ["USERS", "ACTIVITY", "EXTENDED_USERS_EXPORT", "ACTIONABLES_ACTIVITY"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ReportType = ReportType.Impl;

export namespace RightsProbability {
  const values = ["UNPREDICTABLE", "LIKELY", "VERY_LIKELY", "UNLIKELY"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type RightsProbability = RightsProbability.Impl;

export namespace RightsState {
  const values = ["RIGHTS_NOT_REQUESTED", "PENDING", "PENDING_PUBLISHER_APPROVAL", "OK", "NO_RIGHTS"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type RightsState = RightsState.Impl;

export namespace RoleResource {
  const values = [
    "ROLE_ADMIN",
    "PPS_EDITORIAL",
    "PPS_RIGHTS",
    "PPS_BOOKMARKETING",
    "PPS_TOPTENLIST",
    "PPS_WRITERREPORT",
    "PPS_STANDARDREPORT",
    "PPS_ALLSUMMARIES_LIST",
    "PPS_PUBLISHERADMIN",
    "PPS_INTERNALSEARCH",
    "ASP_ACCOUNT_ADMIN",
    "PUBLISHER_STATS_ALL",
    "ASP_STATISTICS",
    "ASP_USER_ADMIN",
    "PPS_RIGHTSADMINLIST",
    "ASP_STATISTICS_INTERNAL",
    "ADMIN_TOOL",
    "CUSTOMER_ADMIN",
    "EMAILTOOL",
    "TRANSACTION_ADMIN",
    "AFFILIATE_CLV_STATISTICS",
    "MARKETING_FILE_UPLOAD",
    "CURRENCY_EXCHANGE_RATE",
    "PPS_EDITORREPORT",
    "ADMIN_RELOAD",
    "EMAILTOOL_SNIPPETS",
    "ASP_MASSIMPORT_TOOL",
    "ASP_USER_ADMIN_LASTACCESS",
    "ASP_CENTRAL_ADMIN",
    "TXT_BUNDLE_EDIT",
    "TXT_TEXT_SINGLEEDIT",
    "TXT_TEXT_MULTIEDIT",
    "TXT_TOBEREVIEWEDLIST",
    "TXT_TOBETRANSLATEDLIST",
    "TXT_INLINE_EDIT",
    "TXT_LATEST_CHANGES",
    "TXT_SEARCH",
    "TXT_MAJOR_CHANGE",
    "TXT_ONLINE_STATE",
    "CREDIT_CARD_SETTLEMENT",
    "TXT_BUNDLE_VIEW",
    "EMAIL_LIST",
    "SOCIAL_COMMENT_FLAG",
    "CUSTOMER_WRITER",
    "RECOMMENDER_EXPORT",
    "KREC_EDIT",
    "WEB_RIGHTS_REQUEST",
    "SCORM_PACKAGE",
    "PPS_VIEW",
    "AFFILIATE_ADMIN",
    "SUMMARY_QR_CODE",
    "MARC_XML",
    "REGENERATE_SUMMARY_FILES",
    "HR_ADMIN_TOOL",
    "SUMMARY_STATISTICS",
    "CF_SUBSCRIPTIONS",
    "ASP_SSO_DOMAIN",
    "CUSTOMER_ADMIN_SUBSCRIPTION",
    "CUSTOMER_ADMIN_FINANCE",
    "OAUTH2_CLIENT_ADMIN",
    "ASP_USER_GROUP_ADMIN",
    "TOP_DOWNLOADS_TOOL",
    "WRITER_PORTAL",
    "ASP_LOGO_UPLOAD",
    "PORTLET_ADMIN",
    "PORTLET_SUPER_ADMIN",
    "FREEABSTRACT_ADMIN_TOOL",
    "PROMOBOXES_ADMIN",
    "CORPORATE_CONTACT",
    "CORPORATE_COMMENTS",
    "PPS_NEW_CONTENT_INPUT",
    "PPS_NEW_CONTENT_INPUT_ADMIN",
    "ABSENCES_ADMIN",
    "CHANNEL_ADMIN",
    "PROMOBOXES",
    "SSO_DOMAINS",
    "PPS_PORTAL",
    "PPS_PORTAL_ADMIN",
    "CHANNEL_LIST",
    "B2B_STATISTICS",
    "LIBRARY_CONNECTOR",
    "CHANNEL_ADMIN_GLOBAL",
    "PPS_FREELANCER_CONTACT_TOOL",
    "PPS_API_PRODUCTION_POINTS",
    "PPS_API_PRODUCTION_POINTS_ADMIN",
    "PPS_API_WRITING_OFFER",
    "PPS_API_EDITING_OFFER",
    "PPS_FLOW",
    "PPS_FLOW_ADMIN",
    "PPS_API_JOB",
    "PPS_API_INVOICE",
    "SCIM_TOKEN_VIEWER",
    "PPS_API_OFFER",
    "PRODUCTION_REPORT",
    "PPS_RIGHTS_FLOW",
    "PPS_AUTHOR_TOOL",
    "GA_KNOWLEDGE_BASE",
    "GA_SERVICE_DESK",
    "PPS_KNOWLEDGE_BASE",
    "EDITORIAL_KNOWLEDGE_BASE",
    "LGXP_ADMIN",
    "PPS_RIGHTS_TEAM_REPORTS",
    "LGXP_GLOBAL_ADMIN",
    "PPS_EDITORIAL_TEAM_REPORTS",
    "PPS_API_SUMMARY_CREATION",
    "CUSTOMER_STORY_ADMIN",
    "PROMOTION_CODE_TOOL",
    "PPS_GLOBAL_MOST_WANTED_SUMMARY_REPORT",
    "ASP_LIST",
    "PPS_USER_API",
    "ADMIN_MARKETING_AUTHOR_TOOL",
    "READ_ONLY_MARKETING_AUTHOR_TOOL",
    "ASP_SENSITIVE_CONTENT",
    "CHANNEL_ADMIN_SEARCH",
    "PPS_TEAM_MOST_WANTED_EDITORIAL_SUMMARY_REPORT",
    "PPS_PERSONAL_MOST_WANTED_EDITORIAL_SUMMARY_REPORT",
    "AUTHOR_CONTACT",
    "PPS_TEAM_MOST_WANTED_RIGHTS_SUMMARY_REPORT",
    "PPS_PERSONAL_MOST_WANTED_RIGHTS_SUMMARY_REPORT",
    "ACTIONABLE_ADMIN",
    "PPS_COMPONENT_DEMO",
    "ASP_MASSIMPORT_TOOL_EXTENDED",
    "ASP_UNIDENTIFIED_USER_ADMIN",
    "PPS_PAYMENT_TOOL",
    "HR_ADMIN_FULL_ACCESS",
    "PPS_ORIGINAL_SUMMARY_FILE_ACCESS",
    "CUSTOMER_REPORTS",
    "PPS_ARCHIVING_SUMMARY",
    "LGXP_ADMIN_SEARCH",
    "PPS_USER_BETA_TEST",
    "ACTIONABLE_ADMIN_SEARCH",
    "CUSTOM_PAGE_ADMIN",
    "PPS_EXPENSE_TOOL",
    "VERIFY_INPUT_TOOL",
    "VERIFY_PUBLISH",
    "VERIFY_UPLOAD",
    "VERIFY_DASHBOARD",
    "CSM_ADVANCED_SEARCH",
    "VERIFY_VERIFICATION",
    "VERIFY_EDITING",
    "SUMMARIES_BULK_LOAD",
    "ACTIONABLES_BULK_LOAD",
  ] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type RoleResource = RoleResource.Impl;

export type RoleResourceConstraint = {
  corporateIds: number[];
  customerId: number;
  languages: Language[];
  resource: RoleResource;
};

export type SearchFiltersForm = {
  audioFormFilter: boolean;
  languageFormFilter: string[];
  publicationDateFormFilter: number[];
  qualityFormFilter: string[];
  query: string;
  ratingFormFilter: number[];
  sourceFormFilter: string[];
  summariesPage: number;
};

export type SummaryBulkLoadForm = {
  corporateId: number | null;
  dataIds: number[];
  includeInactive: boolean;
};

export type SummaryCardDemoProps = {
  summaryList: SummaryMetadata[];
};

export type SummaryCoverInformation = {
  imageUri: string;
  summarySourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST";
  title: string | null;
};

export namespace SummaryDocumentType {
  const values = ["ABSTRACT", "REVIEW"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type SummaryDocumentType = SummaryDocumentType.Impl;

export type SummaryIxStatusView = {
  bookmarkedAt: DateTime<true> | null;
  consumedAt: DateTime<true> | null;
  customerId: number;
  dataId: number;
  likedAt: DateTime<true> | null;
  readingProgress: number;
};

export type SummaryMetadata = {
  activatedAt: DateTime<true> | null;
  activationTime: DateTime<true> | null;
  active: boolean;
  audioActivatedAt: DateTime<true> | null;
  audioActive: boolean;
  audioDuration: number;
  authorInfo: string | null;
  authors: {
    authorId: number;
    firstName: string | null;
    gender: ("MALE" | "FEMALE" | "CUSTOM" | "UNKNOWN") | null;
    lastName: string | null;
    middleName: string | null;
  }[];
  bestseller: boolean;
  businessClassic: boolean;
  canonicalName: string;
  contentItemId: bigint;
  contentItemType: "UNKNOWN" | "SUMMARY" | "CATEGORY" | "DISCUSSION" | "CHANNEL" | "LGXP" | "ACTIONABLE" | "MYLIST" | "CUSTOMPAGE";
  countriesExcluded: Country[];
  countriesIncluded: Country[];
  coverModifiedAt: DateTime<true> | null;
  coverUri: string;
  dataId: number;
  descriptionHtml: string | null;
  documentType: "ABSTRACT" | "REVIEW";
  duration: number;
  imageBorder: boolean;
  language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
  modifiedAt: DateTime<true> | null;
  originalTitle: string | null;
  previewAvailable: boolean;
  publicationDate: string | null;
  publisherInfo: string | null;
  publisherName: string | null;
  rating: number | null;
  sourceType: "BOOK" | "VIDEO" | "ARTICLE" | "CHAPTER" | "REPORT" | "PODCAST";
  subtitle: string | null;
  summaryPages: number;
  summaryWords: number;
  tags: Tag[];
  teaser: string | null;
  title: string | null;
};

export namespace SummarySourceType {
  const values = ["BOOK", "VIDEO", "ARTICLE", "CHAPTER", "REPORT", "PODCAST"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type SummarySourceType = SummarySourceType.Impl;

export type Tag =
  | { discriminator: "dynamicString"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicString; value: string }
  | { discriminator: "dynamicBoolean"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicBoolean; value: boolean }
  | { discriminator: "dynamicNumber"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicNumber; value: number }
  | { discriminator: "dynamicVoid"; exclude: boolean; source: TagSourceType; tagKind: TagKindDynamicVoid }
  | { discriminator: "channel"; exclude: boolean; source: TagSourceType; tagKind: TagKindChannel; value: string }
  | { discriminator: "key-quality"; exclude: boolean; source: TagSourceType; tagKind: TagKindKeyQuality; value: string }
  | { discriminator: "classification"; exclude: boolean; source: TagSourceType; tagKind: TagKindClassification; value: string };

export type TagAdminFilter = {
  query: string;
  tagKind: TagKind;
};

export type TagKind =
  | { discriminator: "classification"; id: bigint; kind: KindClassification }
  | { discriminator: "channel"; id: bigint; kind: KindChannel }
  | { discriminator: "key-quality"; id: bigint; kind: KindKeyQuality }
  | { discriminator: "dynamicVoid"; id: bigint; kind: string }
  | { discriminator: "dynamicString"; id: bigint; kind: string }
  | { discriminator: "dynamicBoolean"; id: bigint; kind: string }
  | { discriminator: "dynamicNumber"; id: bigint; kind: string };

export type TagKindChannel = {
  discriminator: KindChannel;
  id: bigint;
  kind: KindChannel;
};

export type TagKindClassification = {
  discriminator: KindClassification;
  id: bigint;
  kind: KindClassification;
};

export type TagKindDynamicBoolean = {
  discriminator: KindDynamicBoolean;
  id: bigint;
  kind: string;
};

export type TagKindDynamicNumber = {
  discriminator: KindDynamicNumber;
  id: bigint;
  kind: string;
};

export type TagKindDynamicString = {
  discriminator: KindDynamicString;
  id: bigint;
  kind: string;
};

export type TagKindDynamicVoid = {
  discriminator: KindDynamicVoid;
  id: bigint;
  kind: string;
};

export type TagKindKeyQuality = {
  discriminator: KindKeyQuality;
  id: bigint;
  kind: KindKeyQuality;
};

export namespace TagSourceType {
  const values = ["USER", "CHANNEL_HIERARCHY", "AI"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type TagSourceType = TagSourceType.Impl;

export type TagValidationResponse = { status: "success" } | { status: "error"; message: string };

export type TaggedItem = {
  item: ReferencedLearningItem;
  tag: Tag;
};

export type ToastState = {
  message: string;
  title: string | null;
  uuid: string;
  variant: ToastVariant;
};

export namespace ToastVariant {
  const values = ["primary", "success", "warning", "danger"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type ToastVariant = ToastVariant.Impl;

export namespace TransferProtocol {
  const values = ["FTP", "SFTP", "FTPS"] as const;
  export type Impl = (typeof values)[number];
  export function getValues(): typeof values {
    return values;
  }
}
export type TransferProtocol = TransferProtocol.Impl;

export type Translations = {
  keys: { additionalProperties: Record<string, string> };
};

export type ValidationErrorReasons = {
  fields: { additionalProperties: Record<string, string[]> };
  general: string[];
};

export type VerifaiCreatorPageProps = {
  hashId: string;
};

export type VueDemoProps = {
  language: "en" | "de" | "es" | "ru" | "zh" | "pt" | "fr";
  subtitle: string;
};

export type WarmupV2EditForm = {
  country: string;
  email: string;
  frequency: PushFrequency;
  language: Language;
  termsAndConditions: boolean;
};

export type WarmupV2RelayType = { kind: "DEFAULT"; relayState: string } | { kind: "SUMMARY"; relayState: string; summary: SummaryMetadata } | { kind: "ACTIONABLE"; actionable: ActionableListView; relayState: string };

export type WarmupV2Submission = {
  form: WarmupV2EditForm;
  warmupV2Uuid: string;
};

export type WarmupV2ValidatedForm = {
  country: string;
  email: string;
  frequency: PushFrequency;
  language: Language;
  termsAndConditions: boolean;
};

export type WarmupV2ValidationResult = { kind: "SUCCESS"; validatedForm: WarmupV2ValidatedForm } | { kind: "ERROR"; reasons: ValidationErrorReasons };

export type WarmupV2WidgetProps = {
  corporationName: string;
  country: string;
  email: string;
  language: Language;
  relayType: WarmupV2RelayType;
  warmupV2Uuid: string;
};
